body,
input {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #121212;
}

body {
  position: relative;
  height: 100vh;
  margin: 0;
}


.App {
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;

  height: 100vh;
}
@media (min-width: 600px) {
  .App {
    left: 50%;
    transform: translate(-50%);
  }
}

img.logo {
  width: 30px;
  height: 30px;
  padding-right: 10px;
}


.toolbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  background-color: #c0c0c0;
  font-weight: bold;
  padding: 8px 8px;
  z-index: 1;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  max-width: 400px;
  margin-bottom: 10px;
}



.container {
  background-color: #fff;
  border: 3px solid #fff;
  border-radius: 8px;
  padding: 16px;
  /* overflow: hidden; */
  max-width: 100%;
}

.export {
  position: relative;
  background-color: #fff;
  display: inline-block;
}

.exportPadding {
  padding: 16px;
}

@media (min-width: 600px) {

  .toolbar{
    min-width: 400px;
    max-width: 100%;
  }

  .container {
    min-width: 400px;
    /* width: min-content; */
  }

  img.logo {
    padding-right: 20px;
  }

}

.toolbar>.tools {
  flex: none;
}

.toolbar>.tools>i {
  padding: 4px;
  cursor: pointer;
  color: #000;
}

.toolbar>.tools>i.selected {
  color: #2196F3;
}

.toolbar>.tools>i.disabled {
  color: #606060;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.m {
  text-align: left;
  padding: 3px;
  width: min-content;
  min-width: 420px;
}

@media (min-width: 600px) {
  .m {
    padding: 48px;
  }
}


.m>.title {
  color: red;

}


.title>.editable-text>span {
  /* width: 300px; */
  font-weight: 600;
  font-size: 1.5em;
}

.title {
  padding-bottom: 5px;
  ;
}

.r>.heading {

  max-width: 11px;
}

th.heading-a {
  height: 100px;
  /* Set an appropriate height */
  white-space: nowrap;
  /* Prevent text from wrapping */
}

th.heading-a i,
th.heading-a input {
  background-color: #fff;
}

th.heading-a>div {
  transform: translate(13px, 0px) rotate(315deg);
  /* Adjust positioning and rotation */
  width: 22px;
  /* Set an appropriate width */
  white-space: nowrap;
  text-wrap: nowrap;
}

th.heading-a>div>span {
  border-bottom: 1px dashed #a0a0a0;
  /* Add an underline effect */
  padding: 0 10px;
  /* Set padding for readability */
}

th.heading-s div {
  -ms-writing-mode: tb-rl;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  /* transform: rotate(180deg); */
  white-space: nowrap;
  text-wrap: nowrap;

}

.r>.heading-short {
  text-align: center;
}

th {
  font-weight: normal;
  padding-bottom: 5px;
  align-content: end;
}

td {
  padding-right: 5px;
}

td.c {
  width: 22px;
  height: 22px;
  border: 1px solid #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  padding: 0px
}

td.c:hover {
  outline: none;
  border-color: #2196F3;
  box-shadow: 0 0 10px #2196F3;
}

td.comment {
  /* background-image: linear-gradient(225deg, rgb(241, 240, 135), rgb(241, 240, 135) 5px, transparent 5px, transparent); */
  position: relative
}

td.comment:after {
  /* Magic Happens Here!!! */
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  display: block;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-top: 10px solid #f00;
}

/* </magic> */


.grid>tbody>tr>td.c {
  border: 1px solid #a0a0a0;
}

td.nowrap div {
  text-wrap: nowrap;
  white-space: nowrap;
}

h1 {
  margin-bottom: 3px;
  width: fit-content;
}

.desc {
  margin-bottom: 16px;
  color: #606060;
  max-width: 400px;
  /* Full width on mobile */
}

/* Media query for larger screens (e.g., tablets) */
@media (min-width: 600px) {
  .desc {
    max-width: 400px;
    /* Adjust as needed */
  }
}

.dd {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  border: 1px solid #fff;
  border-radius: 4px;
  filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.5));
  white-space: nowrap;
}

.dlg>label {
  font-size: 1.2em;
  font-weight: 600;
}

.dlg button,
.CookieConsent>div>button {
  padding: 8px;
  margin: 8px;
  border: 1px solid #2196F3;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #000;
  font-weight: 600;

}

.dlg textarea {
  width: 100%;
  font-family: inherit;
  font-size: 1em;
  height: 50px;
  outline: none;
}

.dlg>div,
.fnt {
  font-size: 0.8em;
}

.dlg>h2 {
  margin: 0 0 5px 0;
  font-size: 1.1em;
  font-weight: 600;
}

.dlg>h3 {
  margin: 10px 0 2px 0;
  font-size: 1em;
  font-weight: 600;
}

.dlg>div>label>select,
option,
.export select,
.dlg input {
  padding: 8px;
  margin: 8px;
  border: 1px solid #2196F3;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #000;

}

.dlg>div>label>select,
option,
.export select {
  font-weight: 600;
}

.optionsdlg {
  background-color: #ffffff;
  border: 3px solid #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 10px
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background */
  z-index: 100;
  overflow-y: auto;
}




.modal-content {
  /* position: absolute; */
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
  max-width: 80%;
}

@media (min-width: 600px) {
  .modal {
    padding-top:50px;
  }
}





.table {
  border-spacing: 0px;
  margin: 3px;
  padding-right: 50px;
}

th.drag-over-l {
  border-left: 2px solid red;
}

th.drag-over-r {
  border-right: 2px solid red;
}

td.drag-over-t {
  border-top: 2px solid red;
}

td.drag-over-b {
  border-bottom: 2px solid red;
}

.hand {
  cursor: pointer;
}

.squareHost {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two equal columns */
  grid-template-rows: repeat(2, 1fr);
  /* Two equal rows */

}

.square-m {
  width: 23px;
  height: 23px;
  display: table-cell;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  font-size: 10pt;
  font-weight: 500;
}

.square-sm {
  width: 11px;
  height: 11px;
  display: table-cell;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  font-size: 5pt;
  font-weight: 500;
}

.fi-br-triangle-warning {
  color: #FFC107;
}

.fi-br-triangle-info {
  color: #2196F3;
}

.fi-br-triangle-success {
  color: #4CAF50;
}

.fi-br-triangle-danger {
  color: #F44336;
}

.accountable,
.approver {
  background-color: #2196F3;
  color: white
}

.nc {
  font-weight: 800;
  color: #000;
  background-color: #fff;
}

.cat {
  font-weight: 600;
  background-color: #eee;
  height: 23px;
}

.cat>i {
  font-size: 10pt;
  cursor: pointer;
  margin-top: 2px;
  margin-right: 5px;
}

.cat>.editable-text {
  display: flex;
  flex-grow: 1;
}

.catc {
  font-weight: 600;
  background-color: #eee;
  border-right: 1px dashed #a0a0a0;
  color: #a0a0a0;
  font-weight: 100;
}

.catc div {
  display: table-cell;
  text-align: center;
  width: 22px;
}

.nc-inverted {
  font-weight: 800;
  color: #fff;
  background-color: #000;
}

label.accountable,
label.responsible,
label.informed,
label.consulted,
label.support,
label.perform,
label.suggest,
label.control,
label.suggest,
label.omitted,
label.sign-off,
label.verification,
label.advisor,
label.participant,
label.driver,
label.approver,
label.contributor {
  padding: 5px;
  margin: 2px;
  border-radius: 2px;
  font-size: 1.2em;
}

.zoom {
  zoom: 1.5
}

.responsible,
.perform,
.driver {
  background-color: #4CAF50;
  color: white
}

.informed,
.advisor {
  background-color: #9E9E9E;
  color: black
}

.consulted,
.contributor {
  background-color: #FFC107;
  color: black
}

.control {
  background-color: #aa400e;
  color: #fff
}

.omitted {
  background-color: #2c2205;
  color: #fff
}

.shade1 {
  background-color: #c4e1fe;
}

.shade2 {
  background-color: #aad8a8;
}

.shade3 {
  background-color: #ffe8b6;
}

.shade3 {
  background-color: #e2b3a2;
}

.sign-off {
  background-color: #1d581f;
  color: #fff
}

.verification {
  background-color: #121372;
  color: #fff
}

.responsible.accountable,
.perform.accountable,
.driver.approver {
  background-color: #00BCD4;
  color: #fff
}

.support,
.suggest,
.participant {
  background-color: #FF9800;
  color: #000
}

.editable-text>span {
  cursor: pointer;
  flex-grow: 1;
}

.editable-text {
  font-size: 10pt;
  display: flex;
  justify-content: space-between;
}

.editable-text>input, .dlg label input {
  font-size: 10pt;
  border: 1px solid #a0a0a0;
  background: none;
  box-shadow: none;
  border-radius: 2px;
  outline: none;
}

h1>.editable-text>input {
  font-size: 1.5em;
  font-weight: 600;
}

.editable-text>textarea {
  width: 100%;
  font-family: inherit;
  font-size: 1em;
  height: 50px;
  outline: none;
}

.editable-text>span:hover {
  text-decoration: underline;
}

.editable-text>i {
  padding-left: 2px;
  padding-top: 2px;
  cursor: pointer;
}

.grey {
  color: #c0c0c0;
}

.legend {
  font-size: 8pt;
  padding: 5px;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  margin: 5px
}

.legend>div {
  display: flex;
  margin: 1px
}

.hide {
  display: none
}

footer {
  font-size: 0.7em;
  white-space: normal;
  color: #a0a0a0;
  padding: 20px 0px 20px 0px;
}

footer>a {
  color: #00BCD4;
  text-decoration: none;
}

footer>div>img.logo {
  width: 18px;
  height: 18px;
  float: left;
  padding-right: 10px
}

ul.fxlist {
  width: 100%;
  list-style-type: none;
  padding-inline: 0px;
}

.fxlist>li {}

.fxlist>li>div {
  border: 1px solid #ccc;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  margin: 5px;
  padding: 5px;
  outline: none;

}

.fxlist span {
  color: #666;
}

.fxlist h3 {
  font-size: 1em;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.fxlist>li>div:hover {
  border-color: #2196F3;
  box-shadow: 0 0 10px #2196F3;
}